.callDetails {
    .title {
        font-size: 16px;
        margin-bottom: 20px;
        margin-top: 20px;
        color: #409EFF;
    }
    .line {
        display: flex;
        align-items: center;
        line-height: 30px;
        font-weight: 500;
        margin-right: 50px;
        p {
            margin: 0;
            font-weight: 500;
            color: rgba(0, 0, 0, .85);
        }
        .span {
            width: 72px;
            color: #6b6b6b;
        }
    }
    .echarts {
        height: 260px;
        display: flex;
        align-items: flex-start;
        .callStatistics {
            flex: 1;
            height: 100%;
        }
        .tableContents {
            flex: 1;
            padding: 20px 0;
            > h4 {
                font-size: 18px;
                font-family: SourceHanSansCN, SourceHanSansCN-Heavy;
                font-weight: 800;
                color: #2d2d2d;
                padding-left: 20px;
            }
            .callStatisticsTd {
                > div {
                    height: 34px;
                    padding: 0 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        font-size: 14px;
                        font-family: SourceHanSansCN, SourceHanSansCN-Heavy;
                        font-weight: 600;
                        color: #2d2d2d;
                    }
                }
                .callStatisticsActive {
                    background: #f5f5f5;
                    border-radius: 4px;
                    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                }
                .callStatisticsT {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-family: SourceHanSansCN, SourceHanSansCN-Heavy;
                    font-weight: 600;
                    color: #2d2d2d;
                    .dot {
                        display: inline-block;
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    :global {
        .ant-drawer-body {
            padding-top: 0;
        }
    }
}

