.records {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0;
        margin-bottom: 14px;
        .title {
            display: flex;
            align-items: center;
            .span {
                margin-right: 10px;
            }
        }
    }
    .menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 40px 0;
        box-sizing: border-box;
        padding: 0 100px;
        .menuItem {
            padding: 0 40px;
            height: 50px;
            font-size: 12px;
        }
        .active {
            background-color: #39A0FF;
            color: #fff;
        }
    }
    .searchAndLicense {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 60px;
        .search, .license {
            display: flex;
            align-items: center;
            margin-right: 100px;
            .item {
                margin-right: 14px;
            }
            .input {
                width: 300px;
            }
        }
    }
    .line {
        display: flex;
        align-items: center;
        span {
            flex-shrink: 0;
        }
    }
    .operation {
        cursor: pointer;
        transition: .3s;
        color: #409EFF;
        margin-bottom: 0;
        &:hover {
            transform: translateY(-4px);
        }
    }
}