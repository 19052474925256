.index {
    .titleAndTable {
        margin-bottom: 50px;
        margin-top: 10px;
        box-sizing: border-box;
        padding: 10px 20px;
    }
    .table {
        margin-top: 14px;
        .operationBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .operation, .disable {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                } 
            }
            .delete {
                color: #FF818D;
            }
            .disable {
                color: #ccc;
                cursor: not-allowed;
                &:hover {
                    transform: translateY(0px);
                }
            }
        }
        .render {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                margin-bottom: 6px;
            }
            .span {
                transform: translateY(-4px);
                margin: 0 6px;
            }
            .ongoing {
                transform: translateY(-2px);
                color: #409EFF;
            }
        }
    }
}