.problemSeeDetails {
    box-sizing: border-box;
    padding: 10px 20px;
    .title {
        font-size: 20px;
        margin-bottom: 16px;
    }
    .table {
        margin-top: 10px;
        .render {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                margin-bottom: 6px;
            }
            .span {
                transform: translateY(-4px);
                margin: 0 6px;
            }
            .ongoing {
                transform: translateY(-2px);
                color: #409EFF;
            }
        }
    }
    .content {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 14px solid #F3F3F7;
        .methods, .callback {
            height: 318px;
            margin-top: 20px;
            border: 1px solid #4fca91;
            border-radius: 4px;
            background-color: #ebf8f5;
            overflow: hidden;
            transition: 0.3s;
            .contentTitle {
                border-bottom: 1px solid #4fca91;
                display: flex;
                height: 44px;
                padding: 0 4px;
                justify-content: space-between;
                align-items: center;
                .cTop {
                    display: flex;
                    align-items: center;
                    .type {
                        width: 80px;
                        line-height: 33px;
                        background: #50cb92;
                        border-radius: 4px;
                        color: #fff;
                        text-align: center;
                    }
                    .address {
                        font-weight: 600;
                        margin: 0 20px;
                        color: #3c4251;
                    }
                    .name {
                        color: #666;
                        font-size: 12px;
                    }
                }
            }
            .i {
                transition: 0.3s;
                font-size: 12px;
                margin-right: 6px;
                transition: .3s;
            }
            .loading {
                animation: loading 1s infinite linear;
            }
            .up {
                transform: rotate(180deg);
            }
            .url {
                padding: 0 15px;
                line-height: 44px;
                background-color: #fff;
                word-break: break-all;
                font-size: 13px;
                font-weight: 500;
            }
            .option {
                height: 44px;
                display: flex;
                border-bottom: 2px solid #e3e8e7;
                p {
                    width: 90px;
                    line-height: 45px;
                    height: 44px;
                    text-align: center;
                    letter-spacing: 1px;
                    cursor: default;
                }
                .show {
                    color: #0cae63;
                }
            }
            .line {
                transform: translateY(-2px);
                position: relative;
                p {
                    position: absolute;
                    background-color: #0cae63;
                    top: 0;
                    left: 0;
                    height: 2px;
                    width: 90px;
                    transition: 0.3s;
                }
                .t1 {
                    left: 0;
                }
                .t2 {
                    left: 90px;
                }
                .t3 {
                    left: 180px;
                }
            }
            .result {
                padding: 16px;
                background-color: #ebf8f5;
                font-size: 13px;
                p {
                    background-color: #fff;
                    height: 150px;
                    padding: 12px;
                    word-break: break-all;
                    margin-bottom: 0;
                }
            }
        }
        .callback {
            height: 44px;
            .result {
                padding: 16px;
                p {
                    background-color: #fff;
                    height: 126px;
                    padding: 12px;
                    word-break: break-all;
                }
            }
            .cblist {
                padding: 0 16px;
                .item {
                    padding: 16px 0;
                    border-bottom: 1px solid #e3e8e7;
                    .top {
                        display: flex;
                        justify-content: space-between;
                        height: 40px;
                        align-items: center;
                        .myurl {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        .error {
            background-color: #feecec;
            border: 1px solid #fdc1c1;
            .title {
                border-bottom: 1px solid #fdc1c1;
                .type {
                    background-color: #fa3f3e;
                }
            }
            .result {
                background-color: #feecec;
                p {
                    background-color: #fff;
                }
            }
            .option {
                .show {
                    color: #fa3f3e;
                }
            }
            .line {
                p {
                    background-color: #fa3f3e;
                }
            }
            .cblist {
                .item {
                    border-bottom: 1px solid #f5e6e6;
                }
            }
        }
        .openMethods {
            height: 44px;
        }
    }
}
@keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}