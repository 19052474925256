@import '~antd/dist/antd.css';

::-webkit-scrollbar {
	width: 6px !important;
	height: 10px !important;
}
/* 整个滚动条去除button剩下的部分 */
::-webkit-scrollbar-track {
	border-radius: 10px !important;
	background-color: transparent !important;
}
/* 滚动条可拖拽的部分 */
::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
	background-color: #dbdbdb !important;
}
::-webkit-scrollbar-thumb:hover {
	background-color: #adadad !important;
}
::-webkit-scrollbar-thumb:active {
	background-color: #CBCBCB !important;
}
::-webkit-scrollbar-corner {
	background-color: transparent !important;
}
::-webkit-scrollbar-resizer {
	background-color: transparent !important;
}