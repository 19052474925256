.viewEmail {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    .modal {
        max-width: 1000px;
        // min-height: 87vh;
        padding: 10px 20px;
        padding-top: 0;
        box-shadow: 0 0 5px 5px #eee;
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 60px;
            margin-bottom: 20px;
            border-bottom: 1px solid #ddd;
            .span {
                width: 700px;
                font-size: 18px;
                white-space:nowrap;
                overflow:hidden;
                text-overflow:ellipsis;
                color: #6b6b6b;
            }
            .btnBox {
                display: flex;
                align-items: center;
                .btn {
                    margin-left: 14px;
                }
            }
        }
        .innerHtml {
            overflow: hidden;
            img {
                max-width: 960px;
            }
        }
    }
}