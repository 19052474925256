.monitoringHorse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    box-sizing: border-box;
    padding: 0 30px;
    .result {
        display: flex;
        .lineRound {
            height: 0.8rem;
            > span {
                margin: 0 0.4rem;
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 50%;
                display: inline-block;
                background: #1380ff;
                -webkit-animation: line-round 1.5s ease infinite;
                animation: line-round 1.5s ease infinite;
            }
        }
    }
}
.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flexColumn {
    flex-direction: column;
}
.flexNowrap {
    flex-wrap: nowrap;
}
.font {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    > img {
        margin-right: 20px;
    }
}
.green {
    color: #1bbc72;
}
.yellow {
    color: #ff8723;
}
.fontBlod {
    font-weight: 600;
    font-size: 18px;
}
.qiehuan {
    cursor: pointer;
    background: #e4f1ff;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    padding: 6px 30px;
    background: #1380ff;
    border-radius: 4px;
    margin-top: 6px;
    &:hover {
        box-shadow: 0px 2px 10px 0px rgba(19, 128, 255, 0.5);
    }
    .ic {
        margin-left: 6px;
        font-size: 32px;
    }
}
.feed {
    display: -webkit-box;
    overflow: hidden;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-box-orient: vertical;
}
@keyframes line-round {
    0% {
      opacity: 1;
      transform: scale(1.2);
      -webkit-transform: scale(1.2);
    }
    100% {
      opacity: 0.2;
      transform: scale(0.2);
      -webkit-transform: scale(0.2);
    }
}
@-webkit-keyframes line-round {
    0% {
      opacity: 1;
      transform: scale(1.2);
      -webkit-transform: scale(1.2);
    }
    100% {
      opacity: 0.2;
      transform: scale(0.2);
      -webkit-transform: scale(0.2);
    }
}