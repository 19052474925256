.record {
    margin-top: 20px;
    .title {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0 20px 0;
        .scv {
            margin-left: 14px;
        }
    }
    .table {
        .operation {
            cursor: pointer;
            transition: .3s;
            color: #409EFF;
            margin-bottom: 0;
            &:hover {
                transform: translateY(-4px);
            } 
        }
    }
    .line {
        display: flex;
        align-items: center;
        margin-left: 80px;
        margin-bottom: 20px;
        .span {
            display: block;
            width: 150px;
            text-align: right;
            margin-right: 10px;
            color:rgba(0, 0, 0, 0.65);
        }
        .input {
            width: 200px;
        }
    }
    :global {
        .ant-modal-body {
            padding-bottom: 0;
        }
    }
}