.detail {
    .titleBox {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .slash {
            margin: 0 10px;
        }
        .title {
            font-size: 16px;
            margin-bottom: 0;
            cursor: pointer;
            transition: .3s;
            &:hover {
                color: #1890ff;
            }
        }
        span {
            color: rgba(0, 0, 0, .45);
        }
    }
    .information {
        width: 900px;
        border: 1px solid #eee;
        padding: 20px;
        .stTitle {
            font-size: 20px;
            font-weight: 500;
        }
        .line {
            display: flex;
            align-items: center;
            min-height: 30px;
            margin-left: 20px;
            margin-bottom: 10px;
            .span {
                width: 156px;
                text-align: right;
                margin-right: 10px;
                color: #6b6b6b;
            }
        }
    }
    .table {
        margin-top: 20px;
        .operationBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .operation {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                } 
            }
        }
    }
    .refused {
        color: #D25471;
    }
    .agree {
        color: #49CC90;
    }
    .complete {
        color: #87CF63;
    }
}