.added {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    .modal {
        width: 900px;
        padding: 10px 20px;
        box-sizing: border-box;
        box-shadow: 0 0 5px 5px #eee;
        .title {
            font-size: 16px;
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
        }
        .checkLine {
            height: 10px;
        }
        .fragment {
            margin-bottom: 30px;
            margin-left: 100px;
            .line {
                margin-bottom: 14px;
            }
            span {
                color: rgba(0, 0, 0, .65);
                font-size: 12px;
            }
        }
        .btnBox {
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            .btn {
                margin-left: 14px;
            }
        }
        .line {
            display: flex;
            align-items: center;
            min-height: 30px;
            margin-bottom: 30px;
            .input, .upload {
                width: 400px;
                margin-right: 20px;
            }
            .lcash {
                color: #38a4ff;
                font-weight: 400;
                font-size: 20px;
            }
            :global {
                .avatar-uploader {
                    .ant-upload {  
                        width: 300px;
                        height: 150px;
                        img {
                            width: 300px;
                            height: 150px;
                        }
                    }
                }
            }
            .input[disabled] {
                border-color: transparent;
                background-color: #fff;
                cursor: text;
                color: rgba(0, 0, 0, 0.85);
                padding-left: 0;
            }
            .span {
                width: 100px;
                flex-shrink: 0;
                text-align: right;
                color: #6b6b6b;
            }
            .infor {
                display: flex;
                align-items: center;
                img {
                    width: 26px;
                    margin-right: 14px;
                }
            }
            p {
                margin-bottom: 0;
            }
        }
        .baseline {
            align-items: flex-start;
            margin-bottom: 10px;
        }
    }
}