.single {
    border-top: 10px solid #F3F3F7;
    .title {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 16px;
    }
    .authorization {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .author {
            padding: 0 60px;
            font-size: 12px;
        }
        .active {
            border-color: #40A9FF;
            color: #40A9FF;
        }
    }
}