.invoice {
    box-sizing: border-box;
    padding: 0 20px;
    .serach {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .input {
            width: 340px;
            height: 38px;
            border-radius: 2px;
            margin-right: 10px;
        }
        .button {
            width: 100px;
            height: 38px;
        }
        .total {
            margin-left: 20px;
            span {
                color: rgba(0, 0, 0, 0.85);
                font-weight: 600;
            }
        }
    }
    .table {
        margin-top: 20px;
        .operation {
            cursor: pointer;
            transition: .3s;
            color: #409EFF;
            margin-bottom: 0;
            &:hover {
                transform: translateY(-4px);
            }
        }
        .render {
            p {
                margin-bottom: 6px;
            }
        }
    }
}