.invoice {
    box-sizing: border-box;
    padding: 10px 20px;
    .linkBtnCont {
        display: flex;
        align-items: center;
        margin-top: 20px;
        border-bottom: 1px solid #F0F0F0;
        padding-bottom: 14px;
        .linkBtn {
            height: 34px;
            margin-right: 14px;
            border-radius: 50px;
        }
        .catType {
            border: 1px solid #38a4ff;
            color: #38a4ff;
            position: relative;
        }
    }
    .table {
        margin-top: 14px;
        .operationBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .operation, .disable {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                } 
            }
            .agree {
                color: #49CC90;
            }
            .refused {
                color: #FF818D;
            }
            .disable {
                color: #ccc;
                cursor: not-allowed;
                &:hover {
                    transform: translateY(0px);
                }
            }
        }
        .render {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                margin-bottom: 6px;
            }
            .span {
                transform: translateY(-4px);
                margin: 0 6px;
            }
            .ongoing {
                transform: translateY(-2px);
                color: #409EFF;
            }
        }
        .refused {
            color: #D25471;
        }
        .agree {
            color: #49CC90;
        }
        .complete {
            color: #87CF63;
        }
    }
    .modalLine {
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
            margin-bottom: 20px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        span {
            width: 76px;
            text-align: right;
            flex-shrink: 0;
        }
    }
    .adddressItem {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        span {
            font-weight: 600;
            font-size: 12px;
            &:last-child {
                font-weight: 500;
                font-size: 14px;
                color: #333;
            }
        }
    }
}