.appTable {
    .title {
        margin-top: 10px;
        font-size: 20px;
    }
    .stateEnable, .stageEnable {
        color: #67C23A;
        margin-bottom: 0;
    }
    .stateNoEnable {
        color: #F56C6C;
        margin-bottom: 0;
    }
    .stageNoEnable {
        color: #FAB548;
        margin-bottom: 0;
    }
    .operation {
        cursor: pointer;
        transition: .3s;
        color: #409EFF;
        margin-bottom: 0;
        &:hover {
            transform: translateY(-4px);
        }
    }
}