.userDetail {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 58px);
    .menu {
        height: 50px;
    }
    .routeContent {
        flex: 1;
        overflow: auto;
    }
}