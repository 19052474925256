.breadcrumb {
    display: flex;
    align-items: center;
    height: 60px;
    .breadcrumbItem {
        cursor: pointer;
        transition: .1s;
        line-height: 58px;
        &:hover {
            color: #40A9FF;
        }
    }
}