.realTime {
    .top {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        > img {
            width: 48px;
            height: 54px;
        }
        .number {
            margin-left: 20px;
            span {
                font-weight: 600;
                font-size: 16px;
                display: block;
            }
        }
    }
    .echartsLine {
        height: 350px;
    }
    .table {
        margin: 70px 0 50px 0;
        .cell {
            background-color: #F5F7FA;
            font-weight: 500;
            font-size: 16px;
        }
    }
    .instructions {
        margin-top: 20px;
        ul, li {
            list-style: none;
            padding-left: 0;
            li {
                margin-top: 13px;
                line-height: 14px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.45);
                span {
                    color: red;
                }
            }
        }
    }
}