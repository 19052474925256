.projectEditor {
    .breadcrumbItem {
        cursor: pointer;
        transition: .1s;
        line-height: 60px;
        &:hover {
            color: #40A9FF;
        }
    }
    .basicInformation, .security, .ability, .callback {
        border-top: 10px solid #F3F3F7;
        .title {
            margin-top: 10px;
            font-size: 16px;
        }
        .informationContent {
            .line {
                display: flex;
                align-items: center;
                line-height: 50px;
                margin-left: 150px;
                .stateEnable, .stageEnable {
                    color: #67C23A;
                    margin-bottom: 0;
                }
                .stateNoEnable {
                    color: #F56C6C;
                    margin-bottom: 0;
                }
                .stageNoEnable {
                    color: #FAB548;
                    margin-bottom: 0;
                }
                p {
                    margin: 0;
                    font-weight: 500;
                }
                .token, .frequency {
                    margin-left: 30px;
                }
                .vodQps {
                    margin: 0 20px;
                }
                .modify, .copy {
                    color: #409EFF;
                    margin-left: 20px;
                    transition: .3s;
                    cursor: pointer;
                    &:hover {
                        transform: translateY(-2px);
                    }
                }
                .copy {
                    font-size: 20px;
                    user-select: none;
                    &:active {
                        transform: translateY(2px);
                    }
                }
                .state {
                    margin-right: 20px;
                }
                .contractid {
                    width: 200px;
                    margin-right: 20px;
                }
                .contractidBtn {
                    margin-right: 10px;
                }
                .remarksBtn {
                    margin-left: 20px;
                }
                .span {
                    width: 156px;
                    text-align: right;
                    margin-right: 20px;
                    color: #6b6b6b;
                }
            }
        }
    }
}