.media {
    box-sizing: border-box;
    padding: 10px 20px;
    .search {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .input {
            width: 300px;
        }
        .select, .input {
            margin-right: 14px;
        }
    }
    .content {
        margin-top: 20px;
        .pagination {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
        }
        :global {
            .ant-radio-button-wrapper {
                padding: 0 60px;
            }
        }
    }
}

:global {
    .ant-select-item-option-content {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        div {
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
        .anticon {
            font-size: 28px;
        }
    }
}