.dealVersion {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    .modal {
        width: 800px;
        padding: 10px 20px;
        min-height: 87vh;
        box-shadow: 0 0 5px 5px #eee;
        .title {
            font-size: 20px;
            margin-bottom: 16px;
        }
        .content {
            .box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 20px;
                .span {
                    display: block;
                    flex-shrink: 0;
                    margin-bottom: 6px;
                }
                > span {
                    &:last-child {
                        display: flex;
                        flex-direction: row-reverse;
                    }
                }
            }
            .textArea {
                align-items: flex-start;
            }
            .editor {
                margin: 20px 0;
                border: 1px solid #ddd;
            }
        }
        .btnBox {
            display: flex;
            margin-top: 20px;
            justify-content: flex-end;
            .btn {
                margin-left: 14px;
            }
        }
        :global {
            .ant-upload.ant-upload-select-picture-card {
                width: auto;
                border: none;
                height: 36px;
                background-color: transparent;
            }
            .bf-controlbar .control-item {
                line-height: 36px;
            }
        }
        .upload {
            border: none;
            background-color: transparent;
        }
    }
}