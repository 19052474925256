.receive {
    box-sizing: border-box;
    padding: 0 20px;
    .serach {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .input {
            width: 340px;
            height: 38px;
            border-radius: 2px;
            margin-right: 10px;
        }
        .button {
            width: 100px;
            height: 38px;
        }
        .total {
            margin-left: 20px;
            .button {
                width: 200px;
                font-weight: 500;
            }
        }
        .select {
            margin-left: 40px;
        }
    }
    .table {
        margin-top: 20px;
        .operationBox {
            display: flex;
            justify-content: space-around;
            align-items: center;
            .operation {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                }
            }
        }
        .render {
            p {
                margin-bottom: 6px;
            }
        }
    }
}