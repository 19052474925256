.dosage {
    box-sizing: border-box;
    padding: 20px;
    .title {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .select {
            display: flex;
            align-items: center;
            .aSelect {
                margin-left: 20px;
            }
            p {
                margin-bottom: 0;
            }
        }
        .RangePicker {
            margin-left: 40px;
            display: flex;
            align-items: center;
            .activeTime {
                border-color: #409EFF;
                color: #409EFF;
            }
            .item {
                margin-right: 14px;
            }
            p {
                margin-bottom: 0;
                margin-right: 6px;
            }
        }
    }
    .linkBtnCont {
        display: flex;
        align-items: center;
        margin-top: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid #EEEEEE;
        .linkBtn {
            height: 34px;
            margin-right: 14px;
            border-radius: 50px;
        }
        .catType {
            border: 1px solid #38a4ff;
            color: #38a4ff;
            position: relative;
        }
    }
}