.header {
    .content {
        display: flex;
        justify-content: space-between;
        height: 58px;
        border-bottom: 1px solid #eee;
        box-sizing: border-box;
        padding-right: 20px;
        .logoOrBread {
            display: flex;
            .logoBtn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 231px;
                height: 58px;
                background-color: #409EFF;
                transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
                cursor: pointer;
                margin-right: 20px;
                .logoOpen {
                    width: 80px;
                    height: 30px;
                }
                .logoDown {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        .logOut {
            cursor: pointer;
            transition: .3s;
            &:hover {
                transform: translateY(-4px);
            } 
            p {
                line-height: 60px;
                font-weight: 600;
                color: #48a2ff;
                font-size: 14px;
            }
        }
    }
}