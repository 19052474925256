.login {
    height: 100vh;
    background-image: url('../../assets/images/bg.jpg');
    .loginContent {
        position: fixed;
        right: 224px;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateY(-60px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            text-align: center;
            line-height: 80px;
            font-weight: 600;
            color: #48a2ff;
            font-size: 20px;
            margin-bottom: 20px;
            user-select: none;
        }
        .input {
            width: 340px;
            height: 38px;
            border-radius: 4px;
            margin-bottom: 14px;
        }
        .button {
            width: 200px;
            margin-top: 30px;
        }
    }
}