.package {
    margin-top: 20px;
    .title {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .asOfDate {
        display: flex;
        justify-content: center;
    }
    .bag {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        border-bottom: 10px solid #F3F3F7;
        padding-bottom: 40px;
        .comiter {
            width: 400px;
            height: 450px;
            border: 1px solid #ddd;
            border-radius: 10px;
            overflow: hidden;
            .bagTitle {
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 20px;
                font-weight: 600;
                background-color: #409EFF;
            }
            .bagContent {
                box-sizing: border-box;
                padding: 0 14px;
                .inputNumberBox {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100px;
                    border-bottom: 1px dashed #ddd;
                    .inputNumber {
                        width: 150px;
                        height: 40px;
                        border-radius: 4px;
                    }
                    :global {
                        .ant-input-number-input {
                            height: 40px;
                            text-align: center;
                        }
                    }
                }
                .signature {
                    margin-top: 30px;
                    line-height: 30px;
                    text-align: center;
                }
                .uploadBox {
                    display: flex;
                    justify-content: center;
                    .upload {
                        width: 300px;
                    }
                    :global {
                        .avatar-uploader {
                            .ant-upload {  
                                width: 300px;
                                height: 150px;
                                img {
                                    width: 300px;
                                    height: 150px;
                                }
                            }
                        }
                    }
                }
                .btnBox {
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                    .btn {
                        font-size: 12px;
                        padding: 0 20px;
                    }
                }
            }
        }
    }
    .minutes {
        color: #409EFF;
        font-weight: 500;
        font-style: 18px;
    }
    .table {
        margin-top: 40px;
        .finished {
            color: #f36d6f;
        }
        .overdue {
            color: #999;
        }
        .normal {
            color: #6fc643;
        }
        .operationBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .operation {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                } 
            }
        }
    }
}