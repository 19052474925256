.pendingInvoicesDetails {
    .titleBox {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .slash {
            margin: 0 10px;
        }
        .title {
            font-size: 16px;
            margin-bottom: 0;
            cursor: pointer;
            transition: .3s;
            &:hover {
                color: #1890ff;
            }
        }
        span {
            color: rgba(0, 0, 0, .45);
        }
    }
    .information {
        width: 900px;
        border: 1px solid #eee;
        padding: 20px;
        .stTitle, .payment {
            font-size: 16px;
        }
        .payment {
            margin-top: 20px;
            margin-bottom: 10px;
        }
        .lineBox {
            padding: 20px;
            padding-left: 80px;
            padding-bottom: 0;
            border-bottom: 1px solid #eee;
            .line {
                display: flex;
                align-items: center;
                min-height: 30px;
                margin-left: 20px;
                margin-bottom: 10px;
                .span {
                    width: 156px;
                    text-align: right;
                    margin-right: 10px;
                    color: #6b6b6b;
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
        .table {
            .operationBox {
                display: flex;
                align-items: center;
                justify-content: space-around;
                .operation {
                    cursor: pointer;
                    transition: .3s;
                    color: #409EFF;
                    margin-bottom: 0;
                    &:hover {
                        transform: translateY(-4px);
                    } 
                }
            }
        }
        .lineOne {
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eee;
        }
        .btnBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            .dealBtnBox {
                width: 146px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
    .modalLine {
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
            margin-bottom: 20px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        span {
            width: 76px;
            text-align: right;
            flex-shrink: 0;
        }
    }
}