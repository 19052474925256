.program {
    .noOpen {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 250px;
          height: 180px;
        }
        .noOpenSon {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            align-items: center;
            p {
                margin: 20px 0;
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }
    .echartsCont {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 50px 0;
        .roundBox {
            width: 300px;
            height: 250px;
            .echarts {
              transform: translate(60px, 10px);
              width: 180px;
              height: 180px;
            }
            .roundTime {
              text-align: center;
              transform: translateY(-120px);
              p {
                margin-bottom: 0;
              }
            }
            .roundP {
              width: 300px;
              transform: translateY(-50px);
              text-align: center;
            }
        }
    }
    .echartsLine {
        height: 350px;
    }
    .table {
        margin: 70px 0 50px 0;
        .cell {
            background-color: #F5F7FA;
            font-weight: 500;
            font-size: 16px;
        }
    }
    .instructions {
        margin-top: 20px;
        ul, li {
            list-style: none;
            padding-left: 0;
            li {
                margin-top: 13px;
                line-height: 14px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.45);
                span {
                    color: red;
                }
            }
        }
    }
}