.content {
    .area {
        display: flex;
        .router {
            flex: 1;
            height: calc(100vh - 58px);
            overflow: auto;
            transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
        }
    }
}