.enterprise {
    flex: 1;
    box-sizing: border-box;
    padding-left: 32px;
    padding-top: 19px;
    border: 1px solid #ddd;
    border-radius: 6px;
    .unauthorized {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            color: #B3B3B3;
        }
        .add {
            color: #409EFF;
            padding: 0 4px;
            cursor: pointer;
        }
    }
    .title {
        font-size: 16px;
    }
    .head {
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            margin: 0;
        }
    }
    .line {
        display: flex;
        align-items: center;
        min-height: 30px;
        margin-bottom: 20px;
        .input, .upload {
            width: 302px;
            margin-right: 20px;
        }
        :global {
            .avatar-uploader {
                .ant-upload {  
                    width: 300px;
                    height: 150px;
                    overflow: hidden;
                    cursor: pointer;
                }
            }
        }
        .input[disabled] {
            border-color: transparent;
            background-color: #fff;
            cursor: text;
            color: rgba(0, 0, 0, 0.85);
            padding-left: 0;
        }
        .span {
            width: 156px;
            text-align: right;
            margin-right: 20px;
            color: #6b6b6b;
        }
        .infor {
            display: flex;
            align-items: center;
            img {
                width: 26px;
                margin-right: 14px;
            }
        }
    }
    .baseline {
        align-items: flex-start;
    }
    .viewImg {
        width: 100%;
    }
}