.detail {
    box-sizing: border-box;
    padding: 10px 20px;
    .inforContent {
        margin-top: 10px;
        padding: 20px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 14px;
        .infor {
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            span {
                &:first-child {
                    color: #6b6b6b;
                }
                &:last-child {
                    font-weight: 500;
                }
            }
        }
        .btnBox {
            display: flex;
            margin-top: 20px;
            justify-content: flex-end;
            .btn {
                margin-left: 14px;
            }
        }
    }
    .table {
        margin-top: 14px;
        .operationBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .operation, .disable {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                } 
            }
            .delete {
                color: #FF818D;
            }
            .disable {
                color: #ccc;
                cursor: not-allowed;
                &:hover {
                    transform: translateY(0px);
                }
            }
        }
        .render {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                margin-bottom: 6px;
            }
            .span {
                transform: translateY(-4px);
                margin: 0 6px;
            }
            .ongoing {
                transform: translateY(-2px);
                color: #409EFF;
            }
        }
    }
}