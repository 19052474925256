.callEcharts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    .content {
        width: 49.4%;
        height: 380px;
        display: flex;
        border-radius: 10px;
        padding: 20px;
        border: 1px solid #ddd;
        flex-direction: column;
        margin: 10px 0;
        position: relative;
        .chartHeader {
            display: flex;
            flex-direction: column;
            .flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .chartHeader1 {
                > h4 {
                    font-size: 18px;
                    font-family: SourceHanSansCN, SourceHanSansCN-Medium;
                    font-weight: 600;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    > span {
                        font-weight: 600;
                    }
                }
            }
            .chartHeader2 {
                .version {
                    margin-right: 30px;
                }
                span:first-child {
                    font-weight: 600;
                    color: #409EFF;
                }
            }
        }
        .echartsid {
            width: 100%;
            height: 400px;
        }
        .restore {
            position: absolute;
            top: 84px;
            right: 20px;
            padding: 4px 14px;
            border-radius: 3px;
            display: none;
            font-size: 14px;
            font-weight: 600;
            color: #2d2d2d;
            cursor: pointer;
            &:hover {
                opacity: 0.5;
            }
            .ic {
                margin-right: 6px;
            }
        }
    }
}
.flex {
    display: flex;
}
.itemCenter {
    align-items: center;
}
.flexCol {
    flex-direction: column;
}
.justifyContent {
    justify-content: center;
}
.font1 {
    font-size: 12px;
    font-family: SourceHanSansCN, SourceHanSansCN-Medium;
    font-weight: 500;
    color: #2d2d2d;
    white-space:nowrap;
}
.font2 {
    font-size: 14px;
    font-family: SourceHanSansCN, SourceHanSansCN-Medium;
    font-weight: 500;
    color: #a6a6a6;
}
.font2Blod {
    font-weight: 800;
}
.hideEcharts {
    height: 280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 230px;
    }
    p {
        font-weight: 500;
        margin-top: 14px;
    }
}