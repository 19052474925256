.renewal {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    .modal {
        width: 900px;
        padding: 10px 20px;
        box-sizing: border-box;
        box-shadow: 0 0 5px 5px #eee;
        .title {
            font-size: 16px;
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
        }
        .checkLine {
            height: 10px;
        }
        .fragment {
            margin-bottom: 30px;
            margin-left: 100px;
            .line {
                margin-bottom: 14px;
            }
            span {
                color: rgba(0, 0, 0, .65);
                font-size: 12px;
            }
        }
        .btnBox {
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            .btn {
                margin-left: 14px;
            }
        }
        .input {
            width: 300px;
        }
        .textarea {
            width: 350px;
        }
        .descriptions {
            margin: 20px 0;
            :global {
                .ant-descriptions-item-container {
                    align-items: center;
                    margin-bottom: 10px;
                }
            }
        }
    }
}