.index {
    .formalAdd {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .addBox {
        display: flex;
        justify-content: center;
        margin-bottom: 14px;
        .addBtn {
            font-size: 12px;
            padding: 0 40px;
        }
    }
    .span {
        line-height: 50px;
    }
    .table {
        margin-bottom: 50px;
    }
    .operationBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .operation {
            cursor: pointer;
            transition: .3s;
            color: #409EFF;
            margin-bottom: 0;
            &:hover {
                transform: translateY(-4px);
            } 
        }
    }
    .line {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .span {
            width: 80px;
            text-align: right;
            flex-shrink: 0;
        }
        .inputNumber {
            width: 200px;
        }
    }
}