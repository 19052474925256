.overview {
    box-sizing: border-box;
    padding: 10px 20px;
    .search {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .select {
            display: flex;
            align-items: center;
            .span, .input {
                margin-right: 10px;
                white-space: nowrap;
            }
        }
    }
    .table {
        margin-top: 20px;
        .operation {
            cursor: pointer;
            transition: .3s;
            color: #409EFF;
            margin-bottom: 0;
            &:hover {
                transform: translateY(-4px);
            }
        }
        .render {
            p {
                margin-bottom: 6px;
            }
        }
    }
}