.monitoringDetails {
    box-sizing: border-box;
    padding: 10px 20px;
    .table {
        margin-top: 10px;
        .renderDiy {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                margin-bottom: 0;
            }
            .span {
                margin: 0 14px;
            }
        }
        margin-bottom: 30px;
    }
    .echarts {
        .operation {
            display: flex;
            align-items: center;
            .button {
                margin-right: 20px;
                font-size: 12px;
            }
        }
        .echartsContent {
            display: flex;
            justify-content: space-between;
            .echartsItem {
                width: 49.5%;
                margin-top: 20px;
                border: 1px solid #ccc;
                box-sizing: border-box;
                padding: 20px;
                border-radius: 10px;
                .echartsTitle {
                    display: flex;
                    align-items: center;
                    .fontBlod {
                        margin-left: 10px;
                        font-weight: 600;
                        font-size: 18px;
                    }
                    .send {
                        color: #1bbc72;
                    }
                    .ordinary {
                        color: #ff8723;
                    }
                }
                .echartsTitleTwo {
                    display: flex;
                    margin-top: 10px;
                    align-items: center;
                    justify-content: space-between;
                    .echartsTitleTwoMain {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        font-size: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                        p {
                            margin-bottom: 0;
                            margin-right: 4px;
                            color:rgba(0, 0, 0, 0.45);
                        }
                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .echartsMap {
                    margin-top: 20px;
                    .mapItem {
                        margin-top: 30px;
                        .mapTitle {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .div {
                                display: flex;
                                .echartsContentTitke {
                                    margin-right: 10px;
                                    margin-bottom: 0;
                                }
                            }
                            .restore {
                                display: none;
                            }
                        }
                        .echartsData {
                            height: 280px;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
    .modal {
        max-height: 86%;
        overflow-y: auto;
        padding-bottom: 0;
        .modalTitle {
            display: flex;
            align-items: center;
            div {
                display: flex;
                align-items: center;
                margin-right: 20px;
            }
        }
        .content {
            display: flex;
            margin-top: 20px;
            justify-content: space-between;
            .item {
                width: 49.5%;
                border: 1px solid #ccc;
                box-sizing: border-box;
                padding: 20px;
                border-radius: 10px;
                .echartsTitleSend, .echartsTitleReq {
                    .name {
                        margin-left: 10px;
                        font-weight: 600;
                        font-size: 18px;
                    }
                    .send {
                        color: #1bbc72;
                    }
                    .ordinary {
                        color: #ff8723;
                    }
                }
                .echartsDetail {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 10px;
                    .echartsDetailMain {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                        margin-right: 4px;
                        span {
                            font-size: 12px;
                            white-space: nowrap;
                        }
                        .type {
                            color: rgba(0, 0, 0, 0.6);
                            overflow: hidden;
                            text-overflow:ellipsis;
                        }
                    }
                }
            }
        }
        .tableContent {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            .table {
                width: 49.5%;
            }
        }
    }
}
.dit {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
    background: #ccc;
}
.normalBgcolor {
    background: #1bbc72;
}
.ordinaryBgcolor {
    background: #ff8723;
}
.severityBgcolor {
    background: #fe4545;
}