.examine {
	.modal {
		max-height: 86%;
        overflow-y: auto;
        padding-bottom: 0;
		:global {
			.ant-modal-body {
				display: flex;
				justify-content: space-between;
			}
		}
		.modalItem {
			width: 49.5%;
			.echartsItem {
                border: 1px solid #ccc;
                box-sizing: border-box;
                padding: 20px;
                border-radius: 10px;
                .echartsTitle {
                    display: flex;
                    align-items: center;
                    .fontBlod {
                        margin-left: 10px;
                        font-weight: 600;
                        font-size: 18px;
                    }
                    .send {
                        color: #1bbc72;
                    }
                    .ordinary {
                        color: #ff8723;
                    }
                }
                .echartsTitleTwo {
                    display: flex;
                    margin-top: 10px;
                    align-items: center;
                    justify-content: space-between;
                    .echartsTitleTwoMain {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        font-size: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                        p {
                            margin-bottom: 0;
                            margin-right: 4px;
                            color:rgba(0, 0, 0, 0.45);
                        }
                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .echartsMap {
                    margin-top: 20px;
                    .mapItem {
                        margin-top: 30px;
                        .mapTitle {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .div {
                                display: flex;
                                .echartsContentTitke {
                                    margin-right: 10px;
                                    margin-bottom: 0;
                                }
                            }
                            .restore {
                                display: none;
                            }
                        }
                        .echartsData {
                            height: 280px;
                            margin-top: 20px;
                        }
                    }
                }
            }
			.table {
				margin-top: 10px;
			}
		}
	}
	.endModal {
		max-height: 86%;
        overflow-y: auto;
        padding-bottom: 0;
		.modalHead {
			display: flex;
			justify-content: space-between;
			.modalHeadItem {
				width: 49.5%;
				border: 1px solid #ccc;
                box-sizing: border-box;
                padding: 20px;
                border-radius: 10px;
				.user {
					display: flex;
					align-items: center;
					.fontBlod {
						margin-left: 10px;
						font-weight: 600;
						font-size: 18px;
					}
					.send {
						color: #1bbc72;
					}
					.ordinary {
						color: #ff8723;
					}
				}
				.modalHeadItemMain {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 10px;
					div {
						display: flex;
						font-size: 12px;
						white-space: nowrap;
						overflow: hidden;
						p {
							margin-bottom: 0;
							margin-right: 4px;
							color:rgba(0, 0, 0, 0.45);
						}
						span {
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}
		.periodic {
			border: 1px solid #ccc;
			box-sizing: border-box;
			padding: 20px;
			border-radius: 10px;
			margin-top: 10px;
			.lianluTime {
				display: flex;
				align-items: center;
				font-size: 14px;
				color: #6b6b6b;
				font-weight: 600;
				span {
					margin: 0 10px;
				}
				.ongoing {
					color: #008F4A;
				}
				p {
					margin-bottom: 0;
				}
			}
			.linkBox {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 14px;
				.link {
					width: 80%;
					height: 50px;
					display: flex;
					justify-content: space-around;
					align-items: center;
					.formUser, .toUser {
						display: flex;
						align-items: center;
						img {
							width: 30px;
							height: 30px;
							margin-right: 10px;
						}
					}
					.details, .formUser, .toUser {
						width: 230px;
						display: flex;
						justify-content: center;
					}
					.details {
						display: flex;
						align-items: center;
						flex-direction: column;
						p {
							color: #16BDBC;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
	.echartsModal {
		.echarts {
			height: 350px;
		}
	}
	img {
		width: 20px;
	}
}