.auditCustomer {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    .modal {
        width: 900px;
        padding: 10px 20px;
        padding-bottom: 16px;
        box-shadow: 0 0 5px 5px #eee;
        .titleBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 10px;
            margin-bottom: 16px;
            border-bottom: 1px solid #ddd;
            .title {
                font-size: 20px;
                margin-bottom: 0;
            }
        }
        .descriptions {
            margin: 20px 0;
            :global {
                .ant-descriptions-item-container {
                    align-items: center;
                }
            }
        }
        .hoverbox {
            display: flex;
            flex-direction: column;
            width: 140px;
        }
        .fragment {
            margin-bottom: 10px;
            .line {
                margin-bottom: 10px;
            }
            span {
                font-size: 12px;
            }
        }
        .btnBox {
            width: 860px;
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            .btn {
                margin-left: 14px;
                font-size: 12px;
            }
        }
    }
}