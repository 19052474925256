.bill {
    margin-top: 20px;
    .addBill {
        display: flex;
        justify-content: flex-end;
    }
    .title {
        font-size: 16px;
        margin-bottom: 10px;
    }
    .history {
        border-top: 14px solid #F3F3F7;
        padding-top: 10px;
        margin-top: 30px;
    }
    .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0 20px 0;
        .scv {
            margin-left: 14px;
        }
    }
    .table {
        .deduction {
            color: #6CC838;
        }
        .noDeduction {
            color: #FCB63A;
        }
        .operationBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .operation {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                } 
            }
        }
    }
    .line {
        display: flex;
        align-items: center;
        margin-left: 70px;
        margin-bottom: 20px;
        .span {
            display: block;
            width: 150px;
            text-align: right;
        }
        .input {
            width: 200px;
        }
    }
    :global {
        .ant-modal-body {
            padding-bottom: 0;
        }
    }
}