.index {
    box-sizing: border-box;
    padding: 10px 20px;
    .money {
        display: flex;
        align-items: center;
        margin-top: 40px;
        border-bottom: 1px solid #F0F0F0;
        padding-bottom: 30px;
        .moneyItem {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            .type {
                font-size: 18px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.55);
            }
            .number {
                font-size: 30px;
                font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
                font-weight: 500;
            }
        }
    }
    .RangePicker {
        width: 580px;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        .btn {
            font-size: 12px;
            padding: 0 20px;
        }
        .activeTime {
            border-color: #409EFF;
            color: #409EFF;
        }
    }
    .statistical {
        margin-top: 20px;
        border-bottom: 1px solid #F0F0F0;
        padding-bottom: 30px;
        span {
            font-size: 16px;
            font-weight: 500;
        }
        .statis {
            display: flex;
            align-items: center;
            margin-top: 20px;
            .statisItem {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                .type {
                    font-size: 18px;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.55);
                }
                .number {
                    font-size: 20px;
                    font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.75);
                }
            }
        }
    }
    .linkBtnCont {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .linkBtn {
            height: 34px;
            margin-right: 14px;
            border-radius: 50px;
        }
        .catType {
            border: 1px solid #38a4ff;
            color: #38a4ff;
            position: relative;
        }
    }
    .table {
        margin-top: 14px;
        .operationBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .operation, .disable {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                } 
            }
            .delete {
                color: #FF818D;
            }
            .disable {
                color: #ccc;
                cursor: not-allowed;
                &:hover {
                    transform: translateY(0px);
                }
            }
        }
        .render {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                margin-bottom: 6px;
            }
            .span {
                transform: translateY(-4px);
                margin: 0 6px;
            }
            .ongoing {
                transform: translateY(-2px);
                color: #409EFF;
            }
        }
    }
}