.customerAddNext {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    height: calc(100vh - 60px);
    .modal {
        width: 900px;
        padding: 10px 20px;
        box-sizing: border-box;
        box-shadow: 0 0 5px 5px #eee;
        .title {
            font-size: 20px;
            margin-bottom: 30px;
        }
        .fragment {
            margin-bottom: 30px;
            .line {
                margin-bottom: 14px;
            }
            span {
                color: rgba(0, 0, 0, .65);
                font-size: 12px;
            }
        }
        .btnBox {
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            .btn {
                margin-left: 14px;
            }
        }
    }
}