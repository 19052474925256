.realTimeCloud {
    box-sizing: border-box;
    padding: 10px 20px;
    .search {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .input {
            width: 300px;
        }
        .select, .input {
            margin-right: 14px;
        }
    }
    .table {
        margin-top: 26px;
        .operationBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .operation, .disable {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                } 
            }
            .disable {
                color: #ccc;
                cursor: not-allowed;
                &:hover {
                    transform: translateY(0px);
                }
            }
        }
        .render {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                margin-bottom: 6px;
            }
            .span {
                transform: translateY(-4px);
                margin: 0 6px;
            }
            .ongoing {
                transform: translateY(-2px);
                color: #409EFF;
            }
        }
    }
}

:global {
    .ant-select-item-option-content {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        div {
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
        .anticon {
            font-size: 28px;
        }
    }
}