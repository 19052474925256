.basicInfor {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    .modal {
        max-width: 1000px;
        padding: 10px 20px;
        box-shadow: 0 0 5px 5px #eee;
        .title {
            font-size: 16px;
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
        }
        :global {
            .ant-descriptions-item-container {
                align-items: center;
            }
            .ant-descriptions-item {
                padding-bottom: 30px;
            }
        }
        .line {
            display: flex;
            align-items: center;
            p {
                margin: 0;
                font-weight: 500;
            }
            .appNum {
                margin: 0 20px;
            }
            .state {
                margin-right: 20px;
            }
            .contractId {
                width: 150px;
                margin-right: 20px;
            }
            .contractIdBtn {
                margin-right: 10px;
            }
            .remarksBtn {
                margin-left: 20px;
            }
            .span {
                width: 156px;
                text-align: right;
                margin-right: 20px;
                color: #6b6b6b;
            }
        }
    }
}