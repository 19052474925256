.waitInvoice {
    border-top: 14px solid #F3F3F7;
    padding-top: 20px;
    padding-bottom: 10px;
    .title {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .table {
        margin-top: 20px;
        .operationBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .operation {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                } 
            }
            .agree {
                color: #49CC90;
            }
            .refused {
                color: #FF818D;
            }
        }
        .render {
            p {
                margin-bottom: 6px;
            }
        }
    }
    .modalLine {
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
            margin-bottom: 20px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        span {
            width: 70px;
            text-align: right;
            flex-shrink: 0;
        }
    }
}