.navMenu {
    .menu {
        height: calc(100vh - 58px);
        .menuItem {
            height: 50px;
            margin-top: 0;
        }
    }
    :global {
        .anticon, .ant-menu-item-icon {
            line-height: 50px !important;
        }
    }
    .openMenu {
        width: 231px;
    }
}