.basicInformation {
    box-sizing: border-box;
    padding: 20px;
    .title {
        font-size: 20px;
        margin-bottom: 40px;
    }
    :global {
        .ant-descriptions-item-container {
            align-items: center;
        }
    }
    .line {
        display: flex;
        align-items: center;
        p {
            margin: 0;
            font-weight: 500;
        }
        .appNum {
            margin: 0 20px;
        }
        .state {
            margin-right: 20px;
        }
        .contractid {
            width: 200px;
            margin-right: 20px;
        }
        .contractidBtn {
            margin-right: 10px;
        }
        .remarksBtn {
            margin-left: 20px;
        }
        .span {
            width: 156px;
            text-align: right;
            margin-right: 20px;
            color: #6b6b6b;
        }
    }
}