.addRole {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    .modal {
        width: 1000px;
        padding: 10px 20px;
        box-shadow: 0 0 5px 5px #eee;
        .title {
            font-size: 20px;
            margin-bottom: 30px;
        }
        .inputBox {
            display: flex;
            .inputItem {
                display: flex;
                align-items: center;
                margin-right: 20px;
                span {
                    flex-shrink: 0;
                }
                input {
                    width: 300px;
                }
            }
        }
        .allPermissions {
            display: flex;
            align-items: center;
            margin: 30px 0 14px 0;
            .span {
                margin-right: 20px;
            }
        }
        :global {
            .ant-checkbox-wrapper {
                span {
                    font-size: 12px;
                }
            }
            .ant-tree-title {
                font-size: 12px;
            }
        }
        .btnBox {
            margin-top: 30px;
            width: 960px;
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            .btn {
                margin-left: 14px;
            }
        }
    }
}