.callAddus {
    padding: 16px 0;
    margin: 40px 0;
    background-color: #efefef;
    border-radius: 10px;
    padding: 20px 30px;
    .adduserSingle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .adduserSingleAdd {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            .input {
                width: 300px;
            }
            .adduserSingleAddM {
                margin: 0 10px 0 16px;
            }
            .adduserSingleAddIcon {
                cursor: pointer;
            }
        }
    }
    .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        > img {
            margin-right: 6px;
        }
    }
    .multiUser {
        padding: 20px;
        .select {
            margin-right: 20px;
        }
    }
    .userConcreteness {
        padding: 0 20px;
        margin: 20px 0 0;
    }
    .pagination {
        padding: 20px 20px 0;
    }
}
.flex {
    display: flex;
}
.itemsCenter {
    align-items: center;
}
.gen {
    color: #2d2d2d;
    font-size: 10px;
    font-weight: bold;
}