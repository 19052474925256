.topup {
    margin-top: 20px;
    .title {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .bottom {
        border-top: 1px solid #EEEEEE;
        padding-top: 24px;
    }
    .line {
        display: flex;
        align-items: center;
        min-height: 30px;
        margin-left: 100px;
        margin-bottom: 30px;
        .input, .upload {
            width: 300px;
            margin-right: 20px;
        }
        .lcash {
            color: #38a4ff;
            font-weight: 400;
            font-size: 20px;
        }
        :global {
            .avatar-uploader {
                .ant-upload {  
                    width: 300px;
                    height: 150px;
                    img {
                        width: 300px;
                        height: 150px;
                    }
                }
            }
        }
        .input[disabled] {
            border-color: transparent;
            background-color: #fff;
            cursor: text;
            color: rgba(0, 0, 0, 0.85);
            padding-left: 0;
        }
        .span {
            width: 156px;
            text-align: right;
            margin-right: 20px;
            color: #6b6b6b;
        }
        .infor {
            display: flex;
            align-items: center;
            img {
                width: 26px;
                margin-right: 14px;
            }
        }
        p {
            margin-bottom: 0;
        }
    }
    .baseline {
        align-items: flex-start;
        margin-bottom: 10px;
    }
}