.detail {
    .title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;
        .span {
            margin-right: 20px;
            font-weight: 600;
        }
    }
    .renewal {
        margin-left: 14px;
        cursor: pointer;
        color: #409EFF;
        transition: .3s;
        &:hover {
            transform: translateY(-4px);
        }
    }
    .table {
        margin-bottom: 50px;
    }
    .modal {
        padding-left: 40px;
        .modalContent {
            font-size: 12px;
        }
        .line {
            display: flex;
            align-items: center;
            span {
                flex-shrink: 0;
            }
            .inputNumber {
                width: 200px;
            }
        }
    }
}