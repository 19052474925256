.authorList {
    .serach {
        display: flex;
        align-items: center;
        margin: 14px 0;
        .input {
            width: 340px;
            height: 38px;
            border-radius: 2px;
            margin-right: 10px;
        }
        .button {
            width: 100px;
            height: 38px;
            margin-right: 14px;
        }
        .total {
            margin-left: 20px;
            span {
                color: rgba(0, 0, 0, 0.85);
                font-weight: 600;
            }
        }
    }
    .radioBox {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .licenseNum {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background-color: #F5F7FA;
        font-size: 12px;
        color: #6b6b6b;
    }
    .operationBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .operation {
            cursor: pointer;
            transition: .3s;
            color: #409EFF;
            margin-bottom: 0;
            &:hover {
                transform: translateY(-4px);
            } 
        }
        .delete {
            color: #FF818D;
        }
    }
    .btnBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn {
            margin-bottom: 20px;
            padding: 0 40px;
            font-size: 12px;
        }
    }
    .line {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .span {
            width: 80px;
            text-align: right;
            flex-shrink: 0;
        }
        .inputNumber {
            width: 200px;
        }
    }
    .noData {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            color: #ccc;
        }
    }
}