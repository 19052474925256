.monitoring {
    box-sizing: border-box;
    padding: 10px 20px;
    padding-top: 20px;
    .proceed {
        color: #1380ff;
        display: flex;
        align-items: center;
        justify-content: center;
        .proceedDot {
            display: inline-block;
            width: 6px;
            height: 6px;
            background: #1380ff;
            border-radius: 50%;
            margin-right: 6px;
        }
    }
    .table {
        margin-top: 20px;
    }
    .oTsList {
        height: 22px;
        position: relative;
        background: #e4f1ff;
        border-radius: 4px;
    }
    .durationSearch {
        display: flex;
        align-items: center;
        padding: 10px 30px;
        margin: 20px 0;
        background-color: #eee;
        border-radius: 10px;
        .durationSearchSlider {
            flex: 1;
            .justify {
                display: flex;
                justify-content: space-between;
                font-weight: 500;
                color: #6b6b6b;
            }
        }
    }
    .render, .renderDiy {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
            margin-bottom: 0;
        }
    }
    .renderDiy {
        flex-direction: row;
        .span {
            margin: 0 14px;
        }
    }
    .titleBox {
        margin-top: 30px;
        display: flex;
        align-items: center;
        p {
            margin-bottom: 0;
            font-size: 14px;
            padding-bottom: 0;
            border-bottom: none;
        }
        span {
            margin-left: 10px;
            color: #409EFF;
        }
    }
    .shangxiaxing {
        width: 26px;
        height: 26px;
        background-image: url("../../../../assets/images/shangxiaxing.svg");
        background-repeat: no-repeat;
        cursor: pointer;
        display: inline-block;
        line-height: 26px;
    }
    .shangxia {
        background-position: 0 0;
    }
    .shang {
        background-position: -52px 0;
    }
    .xia {
        background-position: -78px 0;
    }
}