.index {
    box-sizing: border-box;
    padding-left: 20px;
    .title {
        color: #555;
        font-size: 14px;
        font-weight: 600;
    }
    .list {
        margin: 20px 0;
        .item {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 260px;
            height: 100px;
            margin: 0 20px 20px 0;
            box-shadow: 0 6px 8px 0 rgb(67 154 248 / 75%);
            background-color: #409EFF;
            border-top: 2px solid #eee;
            cursor: pointer;
            transition: .2s;
            &:hover {
                transform: translateY(-6px);
            }
            .icon {
                font-size: 24px;
                color: #fff !important;
            }
            .infor {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: 30px;
                span:first-child {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                }
                span:last-child {
                    font-size: 20px;
                    font-family: Arial Regular,Arial Regular-Regular;
                    font-weight: 500;
                    color: #fff;
                }
            }
        }
    }
    .RangePicker {
        width: 1020px;
        display: flex;
        margin-top: 60px;
        .activeTime {
            border-color: #409EFF;
            color: #409EFF;
        }
        .item {
            margin-right: 14px;
        }
        p {
            margin-bottom: 0;
            margin-right: 6px;
        }
        .title {
            margin-right: 20px;
        }
    }
    .echartsLine {
        height: 350px;
    }
}