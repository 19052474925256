.billing {
    margin-top: 20px;
    .title {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .line {
        display: flex;
        align-items: center;
        min-height: 30px;
        margin-bottom: 10px;
        .span {
            margin-right: 20px;
            color: #6b6b6b;
        }
        p {
            margin-bottom: 0;
            margin-left: 14px;
            font-size: 12px;
        }
        .btn {
            margin-left: 14px;
        }
    }
    .titleLine {
        display: flex;
        align-items: center;
        height: 24px;
        margin-top: 60px;
        .modify {
            transform: translateY(4px);
        }
    }
    .table {
        margin-top: 20px;
        p {
            margin-bottom: 0;
        }
    }
    .tip {
        margin-top: 20px;
        text-align: center;
        color: #6b6b6b;
    }
    :global {
        .ant-modal-body {
            padding: 0 24px;
            padding-bottom: 24px;
        }
    }
    .addBtn {
        display: flex;
        margin: 20px 0;
        justify-content: center;
    }
    img {
        width: 22px;
        cursor: pointer;
        transition: .15s;
        &:hover {
            transform: translateY(-2px);
        }
    }
    .monthlyBox, .minimumBox {
        padding: 20px 0;
        margin-left: 110px;
        .line {
            display: flex;
            align-items: center;
            min-height: 30px;
            margin-bottom: 10px;
            .span {
                margin-right: 20px;
                color: #6b6b6b;
            }
            p {
                margin-bottom: 0;
                margin-left: 0;
            }
        }
    }
    .minimumBox {
        margin-left: 80px;
        .line {
            .span {
                flex-shrink: 0;
                width: 150px;
                text-align: right;
            }
            .input {
                width: 200px;
            }
        }
    }
    :global {
        .ant-modal-body {
            padding-bottom: 0;
        }
    }
}