.pendhasbeen {
    border-top: 14px solid #F3F3F7;
    padding-top: 20px;
    padding-bottom: 10px;
    .title {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .table {
        margin-top: 20px;
        .operation {
            cursor: pointer;
            transition: .3s;
            color: #409EFF;
            margin-bottom: 0;
            &:hover {
                transform: translateY(-4px);
            }
        }
        .img {
            width: 22px;
        }
        .refused {
            color: #D25471;
        }
        .agree {
            color: #49CC90;
        }
        .complete {
            color: #87CF63;
        }
        .render {
            p {
                margin-bottom: 6px;
            }
        }
    }
}