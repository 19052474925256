.member {
    box-sizing: border-box;
    padding: 10px 20px;
    .addMember {
        display: flex;
        height: 40px;
        justify-content: space-between;
        align-items: center;
        .title {
            font-size: 20px;
            margin-bottom: 0;
        }
    }
    .table {
        margin-top: 14px;
        .operationBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .operation, .disable {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                } 
            }
            .delete {
                color: #FF818D;
            }
            .disable {
                color: #ccc;
                cursor: not-allowed;
                &:hover {
                    transform: translateY(0px);
                }
            }
        }
        .render {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                margin-bottom: 6px;
            }
            .span {
                transform: translateY(-4px);
                margin: 0 6px;
            }
            .ongoing {
                transform: translateY(-2px);
                color: #409EFF;
            }
        }
    }
    .input {
        width: 268px;
    }
    .btnBox {
        width: 712px;
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        .btn {
            margin-left: 14px;
        }
    }
    .formItem {
        margin-bottom: 20px !important;
    }
    .DescrInput {
        width: 240px;
    }
    :global {
        .ant-descriptions-item-container {
            align-items: center;
        }
        .ant-descriptions-item {
            padding-bottom: 20px;
        }
    }
}