.addedDetails {
    box-sizing: border-box;
    padding: 10px 20px;
    .table {
        margin-top: 14px;
        .render {
            display: flex;
            align-items: center;
            justify-content: space-around;
            .operation, .disable {
                cursor: pointer;
                transition: .3s;
                color: #409EFF;
                margin-bottom: 0;
                &:hover {
                    transform: translateY(-4px);
                } 
            }
            .disable {
                color: #ccc;
                cursor: not-allowed;
                pointer-events: none;
                &:hover {
                    transform: translateY(0px);
                }
            }
        }
    }
}
