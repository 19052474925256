.license {
    border-top: 10px solid #F3F3F7;
    .title {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 16px;
    }
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        .radio {
            color: #6b6b6b;
        }
    }
}